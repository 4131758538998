<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        What parts of the course encourage critical thinking in the learning process? Check as many
        as you think apply.
      </p>

      <p v-for="option in options" :key="'pt-1-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.input1" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>

      <s-textarea v-model="inputs.input2" outlined class="mt-2" />
    </v-form>
  </div>
</template>

<script>
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'OleMissSurveyQ3',
  components: {
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
        input2: null,
      },
      options: [
        {text: 'Using an all-electronic lab book instead of paper', value: 'one'},
        {text: 'The pre-lab assignments', value: 'two'},
        {text: 'The notebook checks', value: 'three'},
        {text: 'The post-lab assignments', value: 'four'},
        {text: 'The background and theory pages', value: 'five'},
        {text: 'The labs themselves', value: 'six'},
        {text: 'The three-week critical thinking lab', value: 'seven'},
        {text: 'None', value: 'eight'},
        {text: 'Other (please specify):', value: 'nine'},
      ],
    };
  },
};
</script>
<style scoped></style>
